const MenuIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
	>
		<path
			d="M16 8C16 8.33501 15.8989 8.6563 15.7188 8.89319C15.5388 9.13007 15.2946 9.26316 15.04 9.26316H0.96C0.705392 9.26316 0.461212 9.13007 0.281178 8.89319C0.101143 8.6563 0 8.33501 0 8C0 7.66499 0.101143 7.3437 0.281178 7.10681C0.461212 6.86992 0.705392 6.73684 0.96 6.73684H15.04C15.2946 6.73684 15.5388 6.86992 15.7188 7.10681C15.8989 7.3437 16 7.66499 16 8ZM0.96 2.52632H15.04C15.2946 2.52632 15.5388 2.39323 15.7188 2.15635C15.8989 1.91946 16 1.59817 16 1.26316C16 0.928148 15.8989 0.606858 15.7188 0.36997C15.5388 0.133082 15.2946 0 15.04 0H0.96C0.705392 0 0.461212 0.133082 0.281178 0.36997C0.101143 0.606858 0 0.928148 0 1.26316C0 1.59817 0.101143 1.91946 0.281178 2.15635C0.461212 2.39323 0.705392 2.52632 0.96 2.52632ZM15.04 13.4737H0.96C0.705392 13.4737 0.461212 13.6068 0.281178 13.8437C0.101143 14.0805 0 14.4018 0 14.7368C0 15.0719 0.101143 15.3931 0.281178 15.63C0.461212 15.8669 0.705392 16 0.96 16H15.04C15.2946 16 15.5388 15.8669 15.7188 15.63C15.8989 15.3931 16 15.0719 16 14.7368C16 14.4018 15.8989 14.0805 15.7188 13.8437C15.5388 13.6068 15.2946 13.4737 15.04 13.4737Z"
			fill="#1478FF"
		/>
	</svg>
);

export default MenuIcon;
