const areaOptions = [
	{ id: 1, value: 'Все округа' },
	{ id: 2, value: 'Центральный округ' },
	{ id: 3, value: 'Северный округ' },
	{ id: 4, value: 'Северо-Восточный округ' },
	{ id: 5, value: 'Восточный округ' },
	{ id: 6, value: 'Юго-Восточный округ' },
	{ id: 7, value: 'Южный округ' },
	{ id: 8, value: 'Юго-Западный округ' },
	{ id: 9, value: 'Западный округ' },
	{ id: 10, value: 'Северо-Западный округ' },
	{ id: 11, value: 'Зеленоградский округ' },
];

export { areaOptions };
