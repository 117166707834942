const PlusIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="12"
		height="12"
		viewBox="0 0 12 12"
		fill="none"
	>
		<rect x="5" width="2" height="12" rx="1" fill="#FCFCFC" />
		<rect
			y="7"
			width="2"
			height="12"
			rx="1"
			transform="rotate(-90 0 7)"
			fill="#FCFCFC"
		/>
	</svg>
);

export default PlusIcon;
