// defaultState
const defaultState = {
	center: [37.618879, 55.751426],
	zoom: 10,
	controls: ['zoomControl', 'fullscreenControl'],
};

// Границы Москвы
const displayBorder = [
	[37.286549, 55.503749],
	[37.967616, 56.009657],
];
// Границы РФ
const bordersOfRussia = [
	[19.484764, 41.185996],
	[191.128012, 81.886117],
];
// Округа
const areasCoord = [
	{
		place: 'Все округа',
		coords: [
			[37.286549, 55.503749],
			[37.967616, 56.009657],
		],
	},
	{
		place: 'город Москва',
		coords: [
			[37.286549, 55.503749],
			[37.967616, 56.009657],
		],
	},
	{
		place: 'Центральный округ',
		coords: [
			[37.51441, 55.710276],
			[37.713602, 55.797113],
		],
	},
	{
		place: 'Северный округ',
		coords: [
			[37.331324, 55.759755],
			[37.588665, 55.957373],
		],
	},
	{
		place: 'Северо-Восточный округ',
		coords: [
			[37.519279, 55.782537],
			[37.733958, 55.957565],
		],
	},
	{
		place: 'Восточный округ',
		coords: [
			[37.650154, 55.695286],
			[37.945664, 56.009703],
		],
	},
	{
		place: 'Юго-Восточный округ',
		coords: [
			[37.660593, 55.623087],
			[37.967799, 55.775312],
		],
	},
	{
		place: 'Южный округ',
		coords: [
			[37.569602, 55.567021],
			[37.793346, 55.729463],
		],
	},
	{
		place: 'Юго-Западный округ',
		coords: [
			[37.45575, 55.49133],
			[37.615057, 55.714211],
		],
	},
	{
		place: 'Западный округ',
		coords: [
			[36.888077, 55.582543],
			[37.574471, 55.812701],
		],
	},
	{
		place: 'Северо-Западный округ',
		coords: [
			[37.332618, 55.740674],
			[37.520959, 55.913878],
		],
	},
	{
		place: 'Зеленоградский округ',
		coords: [
			[37.131647, 55.948034],
			[37.268218, 56.021286],
		],
	},
];

export { displayBorder, bordersOfRussia, areasCoord, defaultState };
